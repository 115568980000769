import React, {useState} from "react";
import AuthService from "../services/auth.service";
import {Link, useHistory} from "react-router-dom";
import logo from '../assets/images/Logo.svg';
import profile from '../assets/images/profile.svg';
import toggler from '../assets/images/toggler.svg';
import $ from "jquery";
import arrowBack from "../assets/images/arrow.svg";
import i18next from 'i18next';
import {useTranslation} from "react-i18next";
import { useBetween } from "use-between";
import useShareableState from "../shareableState";

export default function Navigation() {
    const { t, i18n } = useTranslation();
    const [localUnit, setLocalUnit] = useState('meter-cm');
    const [localCurrency, setLocalCurrency] = useState('€');
    const { unit, setUnit, currency, setCurrency } = useBetween(useShareableState);

    let history = useHistory();
    const logout = () => {
        AuthService.logout();
    }
    const toogleNav = () => {
        $('.side-nav').toggleClass('open');
    }
    const closeNav = () => {
        $('.side-nav').removeClass('open');
    }

    const changeDE = (e) =>{
        e.preventDefault()
        i18next.changeLanguage('de', (e) =>{
            console.log(e)
        })
        return true;
    }
    const changeEN = (e) =>{
        e.preventDefault()
        i18next.changeLanguage('en', (e) =>{
            console.log(e)
        })
        return true;
    }

    const currentUser = AuthService.getCurrentUser();
    const obj = (currentUser) ? {currentUser: currentUser, userReady: true} : {redirect: "/login"};
    const [state] = useState(obj);
    if (state.redirect)
        return ('');
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light navbar-mini">
                <Link to={"/profile"} >
                    <img src={profile} alt="Profil bearbeiten" className="img-fluid nav-height"/>
                </Link>

                <a className="navbar-brand" href={"/"}>
                    <img src={logo} alt="Arntz" className="img-fluid nav-height"/>
                </a>
                <span className="open-nav" onClick={toogleNav}>
                <img src={toggler} alt="Navigation" className="img-fluid nav-height"/>
            </span>
                {currentUser && (
                    <div className="side-nav" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto spacer-b-2">
                            <li className="nav-item">
                                <Link to="#" onClick={()=>{history.goBack();$('.side-nav').removeClass('open');}} className="goback ">
                                    <img src={arrowBack} alt="Zurück" className="img-fluid mr-auto"/>
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link to={"/home"} className="nav-link home-icon" onClick={closeNav}>
                                    Home
                                </Link>
                            </li>
                            {currentUser.konsignationsLager &&
                            <li className="nav-item">
                                <Link to={"/stock_management"} className="nav-link stock-icon" onClick={closeNav}>
                                    {t('misc.konsi')}

                                </Link>
                            </li>
                            }
                            {currentUser.masterCalc &&
                            <li className="nav-item">
                                <Link to={"/master_calc"} className="nav-link calc-icon" onClick={closeNav}>
                                    {t('home.master')}
                                </Link>
                            </li>
                            }
                        </ul>
                        <ul className="navbar-nav mr-auto spacer-b-2">
                            <li className="nav-item">
                                <Link to={"/profile"} className="nav-link profil-icon" onClick={closeNav}>
                                    {t('misc.editprofile')}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/last_search"} className="nav-link search-icon" onClick={closeNav}>
                                    {t('misc.last')}
                                </Link>
                            </li>
                        </ul>

                        <ul className="navbar-nav my-2 my-lg-0">
                            <li className="nav-item " onClick={closeNav}>
                                <a className="nav-link logoff-icon" href="/login" onClick={logout}>{t('misc.logout')}</a>
                            </li>
                        </ul>
                        <ul className="navbar-nav my-2 my-lg-0 ">
                            <li className="nav-item d-flex bg-transparent" >
                                <a className="nav-link lang-link" href='#' onClick={changeEN}>EN</a>
                                <a className="nav-link lang-link" href='#' onClick={changeDE}>DE</a>
                            </li>
                        </ul>
                        <ul className="navbar-nav my-2 my-lg-0 ">
                            <li className="nav-item" >
                                <a className="nav-link setting-icon" href="/login" title={t('misc.setting')}
                                   data-toggle="modal" data-target={"#settingModal"}
                                   data-whatever="@mdo"> {t('misc.setting')}</a>
                            </li>
                        </ul>
                    </div>
                )}
            </nav>
            <nav className="navbar navbar-expand-md navbar-ag navbar-big">
                <div className="container">
                    <a className="navbar-brand" href={"/"}>
                        <img src={logo} alt="Arntz" className="img-fluid nav-height"/>
                    </a>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <Link to="#" onClick={()=>history.goBack()} className="goback ">
                                    <img src={arrowBack} alt="Zurück" className="img-fluid mr-auto"/>
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link to={"/home"} className="nav-link home-icon" onClick={closeNav}>
                                    Home
                                </Link>
                            </li>
                            {currentUser.konsignationsLager &&
                            <li className="nav-item">
                                <Link to={"/stock_management"} className="nav-link stock-icon" onClick={closeNav}>
                                    {t('misc.konsi')}
                                </Link>
                            </li>
                            }
                            {currentUser.masterCalc &&
                            <li className="nav-item">
                                <Link to={"/master_calc"} className="nav-link calc-icon" onClick={closeNav}>
                                    {t('home.master')}
                                </Link>
                            </li>
                            }
                        </ul>
                        <ul className="navbar-nav ">
                            <li className="nav-item">
                                <Link to={"/profile"} className="nav-link profil-icon" onClick={closeNav}>
                                    {t('misc.editprofile')}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/last_search"} className="nav-link search-icon" onClick={closeNav}>
                                    {t('misc.last')}
                                </Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav my-2 my-lg-0">
                            <li className="nav-item " onClick={closeNav}>
                                <a className="nav-link logoff-icon" href="/login" onClick={logout}> {t('misc.logout')}</a>
                            </li>
                        </ul>
                        <ul className="navbar-nav my-2 my-lg-0 ">
                            <li className="nav-item d-flex bg-transparent" >
                                <a className="nav-link lang-link" href='#' onClick={changeEN}>EN</a>
                                <a className="nav-link lang-link" href='#' onClick={changeDE}>DE</a>
                            </li>
                        </ul>
                        <ul className="navbar-nav my-2 my-lg-0 ">
                            <li className="nav-item" >
                                <a className="nav-link setting-icon" href="/login" title={t('misc.setting')}
                                   data-toggle="modal" data-target={"#settingModal"}
                                   data-whatever="@mdo"> </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </nav>

            <div className="modal fade" id={"settingModal"} tabIndex="-1" role="dialog" aria-labelledby={"settingModal"} aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t('misc.setting')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="units" className="col-form-label">{t('misc.units')}:</label>

                                    <div className="form-check">
                                        <input  type="radio" className="form-check-input"
                                                name='units'
                                                id="meter-cm"
                                                value="meter-cm"
                                                defaultChecked={true}
                                                onChange={ (e)=>setLocalUnit(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="euro">
                                            Metrisch / metric
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input  type="radio" className="form-check-input"
                                                name='units'
                                                id="inch-feet"
                                                value="inch-feet"
                                                onChange={ (e)=>setLocalUnit(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="dollar">
                                            Imperial / imperial
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="currency" className="col-form-label">{t('misc.currency')}:</label>
                                    <select className="form-control wlb"
                                            name='currency'
                                            id="currency"
                                            onChange={ (e)=>setLocalCurrency(e.target.value)}
                                    >
                                        <option key={1} value={'€'}>EURO (€)</option>
                                        <option key={2} value={'$'}>US dollar ($) </option>
                                        <option key={3} value={'£'}>GBP (£)</option>
                                    </select>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="pl-2 pr-2 mr-2 ml-2 btn btn-primary float-right"  data-dismiss="modal"
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            setCurrency(localCurrency);
                                            setUnit(localUnit);
                                        }}
                                >{t('misc.accept')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );

}